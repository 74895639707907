import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  SET_ACTIONS,
  SET_COLUMNS,
  SET_SELECTED_ROLE_DETAILS,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/roles.module";
import ApiService from "@/core/services/api.service";
import swalAdded from "@/core/services/AlertServices/SwalAdded";

export default {
  mounted() {
    this.$store.dispatch(UPDATE_TABLE_STRUCTURE).finally(() => {
      this.$store.commit(SET_PAGE_LOADING, false);
    });
  },
  methods: {
    toggleNextButton(id, event, tableName) {
      let elem;
      if (event.target.firstElementChild) elem = event.target;
      else elem = event.target.parentElement.parentElement;

      elem.classList.add("bg-light-info");
      elem.firstElementChild.firstElementChild.style.color = "#8950fc";

      const rows = document.querySelectorAll(
        `.${tableName}-tb > .list-container  > tr > td.d-flex .bg-light-info`
      );
      rows.forEach((element) => {
        if (!element.classList.contains(`${tableName}-tb-${id}-next-btn`)) {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        }
      });

      if (tableName === "roles") {
        const pagesRows = document.querySelectorAll(
          `.pages-tb > .list-container  > tr > td.d-flex .bg-light-info`
        );
        pagesRows.forEach((element) => {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        });

        const actionsRows = document.querySelectorAll(
          `.actions-tb > .list-container  > tr > td.d-flex .bg-light-info`
        );
        actionsRows.forEach((element) => {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        });
      } else if (tableName === "pages") {
        const actionsRows = document.querySelectorAll(
          `.actions-tb > .list-container  > tr > td.d-flex .bg-light-info`
        );
        actionsRows.forEach((element) => {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        });
      }

      this.$store.commit(SET_PAGE_LOADING, false);
    },
    handleRoleSelect(pages, id, event, tableName, index) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.commit(SET_SELECTED_ROLE_DETAILS, {
        role_id: id,
        role_index: index,
      });
      this.$store.getters.getROLEPages.forEach((page) => {
        page.is_selected = Boolean(
          pages.find((item) => item.name === page.name)
        );
      });

      this.$store.commit(SET_ACTIONS, { page_id: null, actions: null });
      this.$store.commit(SET_COLUMNS, { action_id: null, columns: null });
      this.toggleNextButton(id, event, tableName);
    },
    handlePageSelect(actions, id, event, tableName) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.commit(SET_ACTIONS, { page_id: id, actions });
      this.$store.commit(SET_COLUMNS, { action_id: null, columns: null });
      this.toggleNextButton(id, event, tableName);
    },
    handleActionSelect(columns, id, event, tableName) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.commit(SET_COLUMNS, { action_id: id, columns });
      this.toggleNextButton(id, event, tableName);
    },
    handleCreateRole(item = null, isForDuplicate = false) {
      this.$refs.createRole.toggleModal(item, isForDuplicate);
    },
    submitRole() {
      // console.log("roles", this.$store.getters.getROLERoles);
      const selectedRoleId =
        this.$store.getters.getROLESelectedRoleDetails.role_id;

      if (selectedRoleId) {
        this.$store.commit(SET_PAGE_LOADING, true);
        const role = this.$store.getters.getROLERoles.find(
          (item) => item.name === selectedRoleId
        );

        const payload = { ...role };
        payload.name = role.title;
        delete payload.title;

        payload.role_id = selectedRoleId;

        // console.log("selected role", role);
        // ApiService.post(`/authorizations/roles/${actionUrl}`, payload)
        ApiService.post(`/authorizations/roles/update`, payload)
          .then(() => {
            swalAdded();
          })
          .finally(() => {
            this.$store.dispatch(UPDATE_TABLE_STRUCTURE).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          });
      }
    },
  },
  computed: {
    tableProperties: function () {
      return {
        title: "Roles",
        description: "Manage Roles",
      };
    },
  },
};
